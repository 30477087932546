<template>
  <div>
    <welcome-conference-banner class='py-8' background-color='#FAFAFA'/>
    <div class='pt-8 px-2'>
      <button
        class='lg:hidden w-full py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
        :style="`background-color: ${eventMainThemeColor};`"
        @click='goToCurrentSession'>
        입장하기
      </button >
    </div>
    <div class='relative pt-8 overflow-x-auto' :style='setupVariableColors'>
      <table class='w-full text-gray-900 text-xs lg:text-sm'>
        <thead>
          <tr>
            <th class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>시간</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left'>프로그램</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left w-1/3 lg:w-auto'>좌장 및 연자</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>13:00 - 13:05</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Opening Remarks & 행사 소개 및 일정 안내</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>
              <div>
                이호승 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(울산대학교 서울아산병원, 대한정형외과학회 진단 및 장애위원회 위원장)</span>
              </div>
              <div class='mt-2'>
                박세진 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(성균관대학교 강북삼성병원, 진단 및 장애위원회 간사)</span>
              </div>
            </td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:05 - 15:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Session I</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이순혁 <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울적십자병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:05 - 13:20</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell w-24'></td>
            <td class='border-b px-2 py-2'>1. Why? 맥브라이드 개정안?</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이호승 <span class='block lg:inline-block lg:ml-1 opacity-70'>(울산대학교 서울아산병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:20 - 13:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>2. 척추</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>안동기 <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울성심병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:40 - 14:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>3. 견주관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>조남수 <span class='block lg:inline-block lg:ml-1 opacity-70'>(제일정형외과병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>14:00 - 14:20</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>4. 수부</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이재성 <span class='block lg:inline-block lg:ml-1 opacity-70'>(중앙대학교병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>14:20 - 14:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>5. 골절, 상지</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>공현식 <span class='block lg:inline-block lg:ml-1 opacity-70'>(분당 서울대학교 병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>14:40 - 15:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='font-semibold bg-gray-200'>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>15:00 - 15:15</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>휴식</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:15 - 16:50</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Session II</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이호승 <span class='block lg:inline-block lg:ml-1 opacity-70'>(울산대학교 서울아산병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:15 - 15:35</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>6. 고관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>임영욱 <span class='block lg:inline-block lg:ml-1 opacity-70'>(가톨릭대학교 서울성모병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:35 - 15:55</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>7. 슬관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>장기모 <span class='block lg:inline-block lg:ml-1 opacity-70'>(고려대학교 안암병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:55 - 16:15</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>8. 족부족관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>박세진 <span class='block lg:inline-block lg:ml-1 opacity-70'>(성균관대학교 강북삼성병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>16:15 - 16:35</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>9. 말초신경</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>김병성 <span class='block lg:inline-block lg:ml-1 opacity-70'>(순천향대학교 부천병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>16:35 - 16:50</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>질의 및 응답</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>16:50 - 16:55</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>Closing Remarks</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'>이호승 <span class='block lg:inline-block lg:ml-1 opacity-70'>(울산대학교 서울아산병원, 대한정형외과학회 진단 및 장애위원회 위원장)</span></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'

export default {
  name: 'KoaCustomProgram',
  components: {
    WelcomeConferenceBanner,
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'colorsInRange',
    ]),
    setupVariableColors () {
      return {
       '--lightestColor': this.colorsInRange[0],
       '--lightColor': this.colorsInRange[1],
       '--inputColor': this.colorsInRange[2],
       '--darkColor': this.colorsInRange[3], 
      }
    },
  },
  methods: {
    goToCurrentSession () {
      this.$router.push({name: 'CurrentSession'}).catch(() => {})
    }
  }
}
</script>
